import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { getErrorMessages, getInvalidFields, SecurityContext } from '@lib';
import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useState } from 'react';
import * as Yup from 'yup';

import {
  CurrentUserDocument,
  LoginDocument,
  UserRole,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import TextInput, { PasswordEndPiece } from '@components/form/TextInput';
import Modal from '@components/modal/Modal';

type LoginProps = {};

const Login: FC<LoginProps> = () => {
  const { resetPasswordControl, signupControl, loginControl } =
    useContext(SecurityContext);
  const apolloClient = useApolloClient();
  const [login] = useMutation(LoginDocument);
  const currentUserQuery = useQuery(CurrentUserDocument, { skip: true });
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email.'),
      password: Yup.string().required('Please enter your password.'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        const { data } = await login({
          variables: { email: values.email, password: values.password },
        });
        // success
        localStorage.setItem('x-token', data.login.accessToken);
        localStorage.setItem('x-refresh-token', data.login.refreshToken);

        await apolloClient.resetStore();
        const usersRefetch = await currentUserQuery.refetch();
        loginControl.close();
        if (
          usersRefetch.data &&
          usersRefetch.data.currentUser?.roles.includes(UserRole.SuperAdmin)
        ) {
          await navigate('/admin/signup-management/');
        } else {
          await navigate('/');
        }
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else console.error('[LoginPage] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal
      control={loginControl}
      contentClassName="h-screen lg:h-auto"
      formProps={{ onSubmit: formik.handleSubmit }}
      size="w-screen h-screen lg:h-auto lg:max-w-md lg:w-full"
    >
      <div className="flex flex-col min-h-full">
        <p className="font-emp font-bold text-2xl text-center lg:text-left mt-48 lg:mt-auto">
          Log In
        </p>
        <p className="lg:hidden opacity-70 text-center font-emp -mt-6 mb-12">
          Welcome back!
        </p>

        <div className="px-6 lg:p-0 flex flex-col">
          <TextInput
            formik={formik}
            name="email"
            label="Email"
            placeholder="eg: you@your-company.com"
            innerClassName="border-t-0 border-l-0 border-r-0"
            autoFocus
          />
          <div className="w-full mt-6 flex flex-row-reverse">
            <a
              href="/request-reset-password/"
              className="no-underline text-white opacity-80"
              onClick={(event) => {
                event.preventDefault();
                loginControl.close();
                resetPasswordControl.open();
              }}
              tabIndex={-1}
            >
              Forgotten your password?
            </a>
          </div>
          <TextInput
            formik={formik}
            name="password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            placeholder={
              showPassword ? 'eg: mySecretPass123' : 'eg: ***************'
            }
            innerClassName="border-t-0 border-l-0 border-r-0"
            endPiece={<PasswordEndPiece setShowPassword={setShowPassword} />}
          />
        </div>
        <div className="flex-1" />
        <div className="hidden lg:flex justify-center mt-12">
          <Button
            variant="outlined"
            className="flex ring-2 ring-interaction-enabled items-center max-w-xs"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Login
          </Button>
        </div>
        <div className="flex justify-center mt-16 font-emp">
          <p>
            <a
              href="/signup/"
              className="no-underline"
              onClick={(event) => {
                event.preventDefault();
                loginControl.close();
                signupControl.open();
              }}
            >
              Don't have an account yet?{' '}
              <span className="text-white font-bold">Register here</span>
            </a>
          </p>
        </div>

        <div className="flex lg:hidden justify-center">
          <Button
            variant="primary"
            className="w-full"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Login
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Login;
