import { useMutation } from '@apollo/client';
import { getErrorMessages, getInvalidFields, SecurityContext } from '@lib';
import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import * as queryString from 'querystring';
import * as React from 'react';
import { FC, useContext } from 'react';
import * as Yup from 'yup';

import { SendSignupLinkDocument } from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import TextInput from '@components/form/TextInput';
import Modal from '@components/modal/Modal';

type SignupProps = {
  siteUrl: string;
};

const RequestSignup: FC<SignupProps> = ({ siteUrl }) => {
  const { signupControl, loginControl } = useContext(SecurityContext);
  const [sendSignupLink] = useMutation(SendSignupLinkDocument);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        const { data } = await sendSignupLink({
          variables: {
            email: values.email,
            origin: siteUrl,
            name: values.name,
          },
        });
        // success
        signupControl.close();
        await navigate(
          `/thank-you?${queryString.encode({
            email: data?.sendSignupLink.email,
            name: data?.sendSignupLink.name,
          })}`
        );
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else console.error('[Signup page] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal
      control={signupControl}
      contentClassName="h-screen lg:h-auto"
      formProps={{ onSubmit: formik.handleSubmit }}
      size="w-screen h-screen lg:h-auto lg:max-w-md lg:w-full"
    >
      <div className="flex flex-col h-full">
        <p className="font-emp font-bold text-2xl text-center lg:text-left mt-48 lg:mt-auto">
          Registration
        </p>
        <p className="lg:hidden opacity-70 text-center font-emp -mt-6 mb-12">
          Glad to have you onboard
        </p>
        <div className="px-6 lg:p-0 flex flex-col">
          <TextInput
            formik={formik}
            name="name"
            label="Full Name"
            placeholder="eg: John Doe"
            innerClassName="border-t-0 border-l-0 border-r-0"
          />
          <TextInput
            formik={formik}
            name="email"
            label="Email"
            placeholder="eg: you@your-company.com"
            innerClassName="border-t-0 border-l-0 border-r-0"
            className="mt-8"
          />
        </div>

        <div className="flex-1" />
        <div className="hidden lg:flex justify-center mt-12">
          <Button
            variant="outlined"
            className="flex ring-2 ring-interaction-enabled items-center max-w-xs"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Create Account
          </Button>
        </div>

        <div className="flex justify-center mt-16 font-emp">
          <p>
            <a
              href="/login/"
              className="no-underline"
              onClick={(event) => {
                event.preventDefault();
                signupControl.close();
                loginControl.open();
              }}
            >
              Already have an account?{' '}
              <span className="text-white font-bold">Log in here</span>
            </a>
          </p>
        </div>

        <div className="flex lg:hidden justify-center">
          <Button
            variant="primary"
            className="w-full"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Create Account
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RequestSignup;
