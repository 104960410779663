import { ModalControl } from '@lib';
import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactNode } from 'react';

import Portal from '@components/Portal';

type ModalProps<TData = any> = {
  contentClassName?: string;
  childrenWrapperClassName?: string;
  control: ModalControl;
  title?: string;
  actions?: ReactNode;
  formProps?: React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >;
  size?: string;
  hideCloseButton?: boolean;
};

const Modal: FC<ModalProps> = ({
  contentClassName,
  childrenWrapperClassName,

  control,
  title,
  children,
  actions,
  formProps,
  size = 'w-screen h-screen lg:max-w-lg lg:w-full',
  hideCloseButton,
}) => {
  function renderContent() {
    const Wrapper = formProps ? 'form' : 'div';

    // for some reason TS does not infer HTMLFormElement correctly
    return (
      <Wrapper
        className={clsx(contentClassName, 'mb-0')}
        {...(Wrapper === 'form' ? (formProps as any) : {})}
      >
        <div
          className={clsx(
            childrenWrapperClassName,
            'bg-background-dark text-white p-4 lg:p-12 lg:pl-16 h-full Modal'
          )}
        >
          {!hideCloseButton && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="fixed left-4 lg:left-auto lg:right-4 top-4 h-6 cursor-pointer"
              viewBox="0 0 20 20"
              fill="currentColor"
              onClick={() => control.close()}
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}
          {title && (
            <h3
              className="text-base lg:text-5xl font-emp font-bold mb-4 text-center lg:text-left"
              id={`modal-headline-${title}`}
            >
              {title}
            </h3>
          )}
          {children}
        </div>
        {actions && (
          <div className="bg-background-dark lg:p-12 lg:pl-16 flex justify-between">
            {actions}
          </div>
        )}
      </Wrapper>
    );
  }

  return (
    <Portal>
      {control.isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto overflow-x-hidden">
          <div className="flex items-center justify-center min-h-screen lg:pt-4 lg:px-4 lg:pb-20 text-center block p-0">
            {/*Background overlay, show/hide based on modal state.*/}
            {/*Entering: "ease-out duration-300"*/}
            {/*  From: "opacity-0"*/}
            {/*  To: "opacity-100"*/}
            {/*Leaving: "ease-in duration-200"*/}
            {/*  From: "opacity-100"*/}
            {/*  To: "opacity-0"*/}
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
              onClick={control.close}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </div>

            {/*This element is to trick the browser into centering the modal*/}
            {/*contents.*/}
            <span
              className="hidden inline-block align-middle h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            {/*Modal panel, show/hide based on modal state.*/}
            {/*Entering: "ease-out duration-300"*/}
            {/*  From: "opacity-0 translate-y-4 translate-y-0 scale-95"*/}
            {/*  To: "opacity-100 translate-y-0 scale-100"*/}
            {/*Leaving: "ease-in duration-200"*/}
            {/*  From: "opacity-100 translate-y-0 scale-100"*/}
            {/*  To: "opacity-0 translate-y-4 translate-y-0 scale-95"*/}
            <div
              className={clsx(
                `inline-block bg-white lg:rounded text-left shadow-xl 
                transform transition-all my-0 lg:my-8`,
                size
              )}
              role="dialog"
              aria-modal="true"
              aria-labelledby={`modal-headline-${title}`}
            >
              {renderContent()}
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
};

export default Modal;
