import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import { FC } from 'react';
import Helmet from 'react-helmet';

// todo import ico file when we have it
const favicon: null = null;

export type SEOProps = {
  title: string;
  description?: string;
  url: string;
  image?: { src: string; width: number; height: number };
  blogPost?: any;
  disableIndex?: boolean;
};

const SEO: FC<SEOProps> = ({
  title,
  description,
  url,
  image,
  blogPost,
  disableIndex = false,
}) => (
  <StaticQuery
    query={seoQuery}
    render={({ site: { siteMetadata } }) => {
      const pageTitle = title || siteMetadata.title;
      const fullUrl = `${siteMetadata.siteUrl}/${
        url.indexOf('/') === 0 ? url.substring(1) : url
      }`;
      const imageUrl = `${siteMetadata.siteUrl}${
        image ? image.src : '/logo_wide.png'
      }`;

      // todo schemaOrgJSONLD
      return (
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
          title={pageTitle}
          titleTemplate={`%s - ${siteMetadata.title}`}
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/ico',
              href: `${favicon}`,
            },
          ]}
        >
          {(siteMetadata.disableIndex || disableIndex) && (
            <meta name="robots" content="noindex" />
          )}

          {/* General tags */}
          <meta charSet="UTF-8" />
          <meta name="description" content={description} />
          <meta name="image" content={imageUrl} />

          {/* Schema.org tags */}

          {/* OpenGraph tags */}
          <meta property="og:url" content={fullUrl} />
          {blogPost ? (
            <meta property="og:type" content="article" />
          ) : (
            <meta property="og:type" content="website" />
          )}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta
            property="og:image:width"
            content={image ? `${image.width}` : '1200'}
          />
          <meta
            property="og:image:height"
            content={image ? `${image.height}` : '628'}
          />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          {/*<meta name="twitter:site" content={twitterLink} />*/}
          {/*<meta name="twitter:creator" content={twitterLink} />*/}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={imageUrl} />
        </Helmet>
      );
    }}
  />
);

export default SEO;

const seoQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        siteUrl
        disableIndex
      }
    }
  }
`;
