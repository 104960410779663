import { useMutation } from '@apollo/client';
import { getErrorMessages, getInvalidFields, SecurityContext } from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useContext } from 'react';
import * as Yup from 'yup';

import { SendResetPasswordLinkDocument } from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import TextInput from '@components/form/TextInput';
import Modal from '@components/modal/Modal';

type ResetPasswordProps = {
  siteUrl: string;
};

const RequestResetPassword: FC<ResetPasswordProps> = ({ siteUrl }) => {
  const { resetPasswordControl } = useContext(SecurityContext);
  const [sendRequestPass] = useMutation(SendResetPasswordLinkDocument);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        await sendRequestPass({
          variables: {
            email: values.email,
            origin: siteUrl,
          },
        });
        // success
        alert('You will receive a reset password link.');
        resetPasswordControl.close();
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else console.error('[Request reset password page] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal
      control={resetPasswordControl}
      contentClassName="h-screen lg:h-auto"
      formProps={{ onSubmit: formik.handleSubmit }}
      size="w-screen h-screen lg:h-auto lg:max-w-md lg:w-full"
    >
      <div className="flex flex-col h-full">
        <div className="px-6 lg:p-0 flex flex-col">
          <p className="font-emp font-bold text-2xl text-center lg:text-left mt-48 lg:mt-auto">
            Forgot Password?
          </p>
          <p className="opacity-70 text-sm text-center lg:text-left font-emp -mt-4 mb-6">
            Enter the email address and we will send you instructions to reset
            your password.
          </p>
          <p className="opacity-70 text-sm text-center lg:text-left font-emp mb-12">
            We will never send your password via email.
          </p>
          <TextInput
            formik={formik}
            name="email"
            label="Email"
            placeholder="eg: you@your-company.com"
            innerClassName="border-t-0 border-l-0 border-r-0"
          />
        </div>

        <div className="flex-1" />
        <div className="hidden lg:flex justify-center mt-12">
          <Button
            variant="outlined"
            className="flex ring-2 ring-interaction-enabled items-center max-w-xs"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Request Password Reset
          </Button>
        </div>

        <div className="flex lg:hidden justify-center">
          <Button
            variant="primary"
            className="w-full"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Request Password Reset
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RequestResetPassword;
