import { SecurityContext } from '@lib';
import clsx from 'clsx';
import { Link, navigate } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useState } from 'react';

import Button from '@components/Button';

import './header.css';

type HeaderProps = {
  url: string;
};

const Header: FC<HeaderProps> = ({ url }) => {
  const { currentUser, logout } = useContext(SecurityContext);
  const [mobileSidebarOpened, setMobileSidebarOpened] = useState(false);

  const shouldShowBack = /^\/media\/[a-zA-Z]+/.exec(url);

  // const shouldShowCross =
  //   /^\/profile/.exec(url) ||
  //   /^\/activate-account/.exec(url) ||
  //   /^\/about-us/.exec(url) ||
  //   /^\/contact/.exec(url) ||
  //   /^\/media/.exec(url) ||
  //   /^\/pricing/.exec(url);

  return (
    <header
      className={`Header-wrapper
      h-10 
      fixed top-0 z-10 lg:static
      bg-transparent lg:bg-background-avg 
      flex
    `}
    >
      {shouldShowBack ? (
        <Button
          variant="none"
          className="lg:hidden m-3 h-8 w-8 text-interaction-light"
          onClick={() => navigate(-1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="-m-1 w-5 h-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      ) : (
        <Button
          variant="outlined-light"
          className="lg:hidden m-3 h-8 w-8 text-interaction-light"
          onClick={() => setMobileSidebarOpened(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="-m-1 w-5 h-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      )}

      {mobileSidebarOpened && (
        <div
          className="absolute z-20 inset-0 w-screen h-screen bg-background-avg opacity-50"
          onClick={() => setMobileSidebarOpened(false)}
        />
      )}
      <div
        className={clsx(
          `
        flex-1 m-auto max-w-6xl 
        lg:justify-between 
        lg:px-4 
        flex flex-col lg:flex-row
        bg-background-avg lg:bg-background-transparent
        absolute lg:static z-20 top-0 left-0 
        h-screen lg:h-auto w-72 lg:w-auto 
        transform lg:transform-none transition-transform
      `,
          mobileSidebarOpened && 'translate-x-0',
          !mobileSidebarOpened && '-translate-x-full'
        )}
      >
        <Link
          className={`
            flex text-white no-underline uppercase self-center items-center
            h-24 lg:h-auto w-full lg:w-auto
            text-2xl lg:text-xs 
            pl-10 lg:pl-0
            bg-background-dark lg:bg-transparent
          `}
          to="/"
        >
          {/*Oilynx LogoMark + Type - Horizontal - Main svg.svg*/}
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500.02 93.78"
            className="h-5"
          >
            <defs>
              <style>{`.cls-1{ fill:#9cc6ff; }.cls-2{ fill:#fff; }`}</style>
            </defs>
            <path
              className="cls-1"
              d="M73.28,43.65h0a1.54,1.54,0,0,1,1.55.87l10,39.62A5.93,5.93,0,0,1,83.34,90h0c-4.26,4.71-14.08,5.09-19.11.75L64,90.54a6.29,6.29,0,0,1-2.4-6L71.72,44.58A1.49,1.49,0,0,1,73.28,43.65Z"
            />
            <path
              className="cls-1"
              d="M73.27,21c15.41,0,28.09,21.76,29.7,49.71a2.81,2.81,0,0,0,2.81,2.67h37.95a2.82,2.82,0,0,0,2.82-2.91A73.33,73.33,0,0,0,0,70.42a2.82,2.82,0,0,0,2.82,2.91h38a2.82,2.82,0,0,0,2.81-2.67C45.19,42.71,57.87,21,73.27,21Z"
            />
            <path
              className="cls-2"
              d="M202.3,69.76a25,25,0,0,1-9.81-9.41,26,26,0,0,1-3.55-13.46,26,26,0,0,1,3.55-13.46A25.09,25.09,0,0,1,202.3,24a30.67,30.67,0,0,1,28,0,25.09,25.09,0,0,1,9.81,9.38,25.87,25.87,0,0,1,3.56,13.49,25.93,25.93,0,0,1-3.56,13.5,25.06,25.06,0,0,1-9.81,9.37,30.59,30.59,0,0,1-28,0Zm23.17-7.22a16.57,16.57,0,0,0,6.39-6.42,18.45,18.45,0,0,0,2.32-9.23,18.47,18.47,0,0,0-2.32-9.23,16.63,16.63,0,0,0-6.39-6.42,19.35,19.35,0,0,0-18.3,0,16.59,16.59,0,0,0-6.4,6.42,18.47,18.47,0,0,0-2.32,9.23,18.45,18.45,0,0,0,2.32,9.23,16.53,16.53,0,0,0,6.4,6.42,19.35,19.35,0,0,0,18.3,0Z"
            />
            <path
              className="cls-2"
              d="M262.28,21.36h6.85a1.3,1.3,0,0,1,1.3,1.3V71.13a1.3,1.3,0,0,1-1.3,1.3h-6.85a1.3,1.3,0,0,1-1.3-1.3V22.66A1.3,1.3,0,0,1,262.28,21.36Z"
            />
            <path
              className="cls-2"
              d="M292.61,21.36h7.06a1.19,1.19,0,0,1,1.19,1.19V63.21a1.19,1.19,0,0,0,1.19,1.19h24.2a1.19,1.19,0,0,1,1.19,1.19v5.65a1.19,1.19,0,0,1-1.19,1.19H292.61a1.19,1.19,0,0,1-1.19-1.19V22.55A1.19,1.19,0,0,1,292.61,21.36Z"
            />
            <path
              className="cls-2"
              d="M358.82,54.76V71.17a1.27,1.27,0,0,1-1.26,1.26h-6.93a1.26,1.26,0,0,1-1.26-1.26V54.9a1.25,1.25,0,0,0-.18-.65l-18.64-31a1.25,1.25,0,0,1,1.08-1.9h7.15a1.26,1.26,0,0,1,1.08.61l14.27,23.85a.29.29,0,0,0,.5,0L369.05,22a1.28,1.28,0,0,1,1.08-.6h6.36a1.26,1.26,0,0,1,1.08,1.9L359,54.11A1.22,1.22,0,0,0,358.82,54.76Z"
            />
            <path
              className="cls-2"
              d="M436.6,22.47V71.31a1.12,1.12,0,0,1-1.12,1.12h-6.12a1.16,1.16,0,0,1-.87-.42L402.22,39.6a.8.8,0,0,0-1.42.5V71.31a1.12,1.12,0,0,1-1.12,1.12h-7.14a1.12,1.12,0,0,1-1.11-1.12V22.47a1.11,1.11,0,0,1,1.11-1.11h6.12a1.12,1.12,0,0,1,.87.41l26.41,32.57a.73.73,0,0,0,1.29-.46V22.47a1.12,1.12,0,0,1,1.12-1.11h7.13A1.12,1.12,0,0,1,436.6,22.47Z"
            />
            <path
              className="cls-2"
              d="M489.42,72.24,476.11,53.53a.44.44,0,0,0-.72,0L462.22,72.24a.42.42,0,0,1-.36.19h-9.65a.44.44,0,0,1-.36-.7L470,46.64a.45.45,0,0,0,0-.52L452.86,22.05a.44.44,0,0,1,.36-.69h9.59a.45.45,0,0,1,.36.18L475.68,39a.45.45,0,0,0,.72,0l12.36-17.48a.45.45,0,0,1,.36-.18h9.15a.44.44,0,0,1,.36.7l-17.1,23.7a.44.44,0,0,0,0,.51l18.4,25.46a.44.44,0,0,1-.35.7h-9.81A.42.42,0,0,1,489.42,72.24Z"
            />
          </svg>
        </Link>

        <div
          className={`Header-navigation-menu 
          flex font-emp
          lg:items-center
          space-y-4 lg:space-y-0
          mt-4 lg:mt-0
          flex-col lg:flex-row
        `}
          onClick={() => {
            setMobileSidebarOpened(false);
          }}
        >
          <Link className={clsx(url === '/' && 'active')} to="/">
            Trades
          </Link>
          <Link
            className={clsx(url === '/shipping' && 'active')}
            to="/shipping/"
          >
            Shipping
          </Link>
          <Link
            className={clsx(url.indexOf('/media') === 0 && 'active')}
            to="/media/"
          >
            Media
          </Link>
          {/*<Link*/}
          {/*  className={clsx(url.indexOf('/pricing') === 0 && 'active')}*/}
          {/*  to="/pricing/"*/}
          {/*>*/}
          {/*  Pricing*/}
          {/*</Link>*/}
          <Link
            className={clsx(url.indexOf('/about') === 0 && 'active')}
            to="/about-us/"
          >
            About us
          </Link>
          <Link
            className={clsx(url.indexOf('/contact') === 0 && 'active')}
            to="/contact/"
          >
            Contact Us
          </Link>
          <div className="lg:hidden flex flex-col">
            <hr className="mx-4 bg-white opacity-10 h-0.5" />
            {currentUser ? (
              <>
                <Link className="mb-4" to="/profile/">
                  My Profile
                </Link>
                <Link className="mb-4" to="/notifications/">
                  Notifications
                </Link>
              </>
            ) : (
              <Link className="mb-4" to="/login/">
                Log In / Register
              </Link>
            )}
            <hr className="mx-4 bg-white opacity-10 h-0.5" />
            <Link className="mb-4" to="/terms-and-conditions/">
              Terms & Conditions
            </Link>
            {currentUser && (
              <a
                className="no-underline"
                href="/logout/"
                onClick={async (event) => {
                  event.preventDefault();
                  await logout();
                  return navigate('/');
                }}
              >
                Log out
              </a>
            )}
          </div>
        </div>

        <div className="hidden lg:flex items-center">
          {currentUser ? (
            <>
              <Link
                className="text-white no-underline mx-3 block w-4"
                to="/notifications/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                </svg>
              </Link>
              <Link
                className="text-white no-underline block w-4"
                to={'/profile/'}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
            </>
          ) : (
            <Link
              className="text-white no-underline block flex whitespace-nowrap text-sm"
              to={'/login/'}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              Log In / Register
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
