import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { graphql, StaticQuery } from 'gatsby';
import Link from 'gatsby-link';
import * as React from 'react';
import { FC, ReactNode, useContext, useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Toaster } from 'react-hot-toast';

import { ServerVersionDocument, UserRole } from '@redsleeve/oilynx-domain';

import SEO, { SEOProps } from '@components/SEO';

import Footer from '@layouts/Footer';
import Header from '@layouts/Header';
import Login from '@layouts/Login';
import Notifications from '@layouts/Notifications';
import RequestResetPassword from '@layouts/RequestResetPassword';
import RequestSignup from '@layouts/Signup';

import { useApolloNetworkStatus } from '@lib/graphql';
import { SecurityContext } from '@lib/security';

import './index.css';

type LayoutProps = SEOProps & {
  aboveMainContent?: ReactNode;
  hideFooter?: boolean;
};

const Layout: FC<LayoutProps> = ({
  children,
  aboveMainContent,
  url,
  hideFooter,
  ...seoProps
}) => {
  const { numPendingQueries, numPendingMutations } = useApolloNetworkStatus();
  const { currentUser, setWebPushPublicKey } = useContext(SecurityContext);
  const versionQuery = useQuery(ServerVersionDocument);

  useEffect(() => {
    if (versionQuery.data?.webPushPublicKey) {
      setWebPushPublicKey(versionQuery.data?.webPushPublicKey);
    }
  }, [versionQuery, setWebPushPublicKey]);

  return (
    <>
      <StaticQuery
        query={query}
        render={({ site: { siteMetadata } }) => (
          <div
            className={clsx(
              'bg-background-dark',
              'flex flex-col min-h-screen text-white overflow-hidden'
            )}
          >
            <SEO url={url} {...seoProps} />
            <Toaster position="bottom-right" reverseOrder={false} />
            <div
              className={clsx(
                'LoadingIndicator fixed z-40 h-1 top-0 w-screen pointer-events-none shadow-sm',
                numPendingQueries > 0 ? 'block' : 'hidden'
              )}
            />
            <div
              className={clsx(
                'fixed z-50 h-screen w-screen bg-background-avg bg-opacity-30 flex items-center justify-center',
                numPendingMutations > 0 ? 'block' : 'hidden'
              )}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <svg
                className="w-24 animate-bounce"
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 176.96 113.24"
              >
                <defs>
                  <style>{`.cls-1{ fill:#9cc6ff; }`}</style>
                </defs>
                <path
                  className="cls-1"
                  d="M88.49,52.71h0a1.88,1.88,0,0,1,1.87,1l12.06,47.86a7.13,7.13,0,0,1-1.79,7.06h0c-5.14,5.69-17,6.16-23.07.91l-.3-.26c-2.37-2-3.42-4.66-2.9-7.25L86.6,53.84A1.81,1.81,0,0,1,88.49,52.71Z"
                />
                <path
                  className="cls-1"
                  d="M88.48,25.3c18.6,0,33.91,26.28,35.86,60a3.4,3.4,0,0,0,3.39,3.23h45.82A3.41,3.41,0,0,0,177,85,88.55,88.55,0,0,0,0,85a3.41,3.41,0,0,0,3.41,3.51H49.23a3.4,3.4,0,0,0,3.39-3.23C54.57,51.58,69.88,25.3,88.48,25.3Z"
                />
              </svg>
            </div>

            <Header url={url} />
            {aboveMainContent}
            <main className="max-w-6xl w-full flex-1 m-auto px-4 flex flex-col min-h-screen">
              <div className="hidden lg:flex">
                {currentUser?.roles.includes(UserRole.SuperAdmin) ? (
                  <p>
                    Super admin menu:
                    <Link className={'pl-4'} to="/admin/signup-management/">
                      Signup Management
                    </Link>
                    <Link className={'pl-4'} to="/admin/user-management/">
                      User Management
                    </Link>
                    <Link className={'pl-4'} to="/admin/category-management/">
                      Category Management
                    </Link>
                    <Link
                      className={'pl-4'}
                      to="/admin/price-index-management/"
                    >
                      Price Index Management
                    </Link>
                  </p>
                ) : null}
              </div>

              {children}
            </main>
            <Footer
              className={clsx(hideFooter && 'hidden')}
              version={`${siteMetadata.env} ${siteMetadata.version}`}
              serverVersion={
                versionQuery.error
                  ? 'disconnected'
                  : versionQuery.loading
                  ? 'loading'
                  : `${versionQuery.data?.env} ${versionQuery.data?.version}`
              }
            />
            <RequestResetPassword siteUrl={siteMetadata.siteUrl} />
            <RequestSignup siteUrl={siteMetadata.siteUrl} />
          </div>
        )}
      />
      <Notifications />
      <Login />
      <CookieConsent
        buttonStyle={{
          backgroundColor: '#5289FF',
          color: '#fff',
          borderRadius: '0.25rem',
        }}
        containerClasses="transition-opacity duration-700 ease-in-out"
        cookieName="oilynx-cookie-consent"
      >
        This website uses cookies to enhance the user experience.
        <br />
        <span className="text-xs">
          See{' '}
          <Link to="/cookie-policy/" className="text-interaction-enabled">
            Cookie policy
          </Link>{' '}
          and{' '}
          <Link
            to="/terms-and-conditions/"
            className="text-interaction-enabled"
          >
            Terms and Conditions
          </Link>
          .
        </span>
      </CookieConsent>
    </>
  );
};

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        version
        env
        siteUrl
      }
    }
  }
`;

export default Layout;
