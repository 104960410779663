import clsx from 'clsx';
import { Link } from 'gatsby';
import * as React from 'react';
import { FC, useEffect } from 'react';

type FooterProps = {
  version: string;
  serverVersion: string;
  className: string;
};

const Footer: FC<FooterProps> = ({ version, serverVersion, className }) => {
  useEffect(() => {
    console.debug({ version, serverVersion });
  }, [serverVersion]);

  return (
    <footer className={clsx(className, 'bg-background-avg')}>
      <div className="flex flex-col lg:flex-row p-4 text-center">
        <div className="flex-1 flex flex-col space-y-2 mb-4">
          <Link className="text-interaction-light" to="/">
            Trades
          </Link>
          <Link className="text-interaction-light" to="/shipping/">
            Shipping
          </Link>
          <Link className="text-interaction-light" to="/media/">
            Media
          </Link>
          <Link className="text-interaction-light" to="/about-us/">
            About us
          </Link>
          <Link className="text-interaction-light" to="/contact/">
            Contact Us
          </Link>
        </div>
        <div className="flex-1 flex flex-col space-y-2">
          <Link className="text-interaction-light" to="/terms-and-conditions/">
            Terms & Conditions
          </Link>
          <Link className="text-interaction-light" to="/disclaimer/">
            Disclaimer
          </Link>
          <Link className="text-interaction-light" to="/cookie-policy/">
            Cookie Policy
          </Link>
        </div>
      </div>
      <p className="text-center opacity-75 text-sm">
        © 2023 Redsleeve SA, Genève, Switzerland
      </p>
    </footer>
  );
};

export default Footer;
