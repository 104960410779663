import { useMutation, useQuery } from '@apollo/client';
import { SecurityContext } from '@lib';
import clsx from 'clsx';
import { Link } from 'gatsby';
import * as React from 'react';
import { FC, useContext } from 'react';

import {
  GetNotificationsDocument,
  MarkAsReadDocument,
  Notification_Type,
  Trade_Type,
} from '@redsleeve/oilynx-domain';

import Modal from '@components/modal/Modal';

import { formatTimeUntilNow } from '@lib/format';

import buyIcon from '../resources/buyIcon.svg';
import sellIcon from '../resources/sellIcon.svg';

type NotificationsProps = {};

const Notifications: FC<NotificationsProps> = () => {
  const { notificationsControl } = useContext(SecurityContext);
  const notifications = useQuery(GetNotificationsDocument);
  const [markAsRead] = useMutation(MarkAsReadDocument);

  return (
    <Modal
      control={notificationsControl}
      contentClassName="min-h-screen lg:min-h-0 bg-background-dark"
      size="w-screen min-h-screen lg:min-h-0 lg:max-w-3xl lg:w-full"
    >
      <div className="flex flex-col flex-1">
        <p className="font-emp font-bold text-base lg:text-3xl text-center lg:text-left">
          Notifications
        </p>

        <div className="space-y-2">
          {notifications.data?.getNotifications.map((notification) => (
            <a
              key={notification.id}
              href={notification.trade.link}
              className={clsx(
                'group flex flex-row items-center space-x-4 p-3 cursor-pointer no-underline',
                notification.read
                  ? 'hover:bg-background-avg'
                  : 'bg-background-avg hover:bg-background-light'
              )}
              onClick={() => {
                // no need to prevent default
                markAsRead({
                  variables: { notificationId: notification.id },
                }).catch(
                  console.warn.bind(null, 'Error marking notification as read.')
                );
                // don't wait for read notification
                notificationsControl.close();
              }}
            >
              <div className="rounded-full bg-background-avg group-hover:bg-background-light p-2">
                <img
                  className="w-3 h-3 m-0"
                  src={
                    notification.type === Notification_Type.Trade
                      ? notification.trade.tradeType === Trade_Type.Buy
                        ? buyIcon
                        : sellIcon
                      : undefined
                  }
                  alt={
                    notification.type === Notification_Type.Trade
                      ? notification.trade.tradeType === Trade_Type.Buy
                        ? 'buy icon'
                        : 'sell icon'
                      : undefined
                  }
                />
              </div>
              <div className="flex-1 text-sm lg:text-base">
                {notification.type === Notification_Type.Trade ? (
                  <>
                    {notification.trade.contactInfo.companyName} is{' '}
                    {notification.trade.tradeType === Trade_Type.Buy
                      ? 'buying'
                      : 'selling'}{' '}
                    <span className="text-interaction-enabled">
                      {notification.trade.categoryName}
                    </span>{' '}
                    for {notification.trade.price}.
                  </>
                ) : undefined}{' '}
                <span className="opacity-60">
                  {formatTimeUntilNow(notification.createdAt)}
                </span>
              </div>
              <div className="hidden lg:block p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-transparent group-hover:text-interaction-light"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </a>
          ))}
        </div>

        {!notifications.data?.getNotifications.length && (
          <div className="text-interaction-disabled mb-20">
            <p>You don't have any notifications.</p>
            <p>
              <Link
                to="/profile/notifications"
                className="no-underline text-sm opacity-70"
                onClick={() => {
                  notificationsControl.close();
                }}
              >
                You can manage your notifications settings from{' '}
                <span className="font-bold underline">your profile page</span>.
              </Link>
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Notifications;
