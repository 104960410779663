import { isSSR } from '@lib';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  portalGroup?: string;
};

const InternalPortal: FC<PortalProps> = ({
  children,
  portalGroup = 'modal',
}) => {
  let modalRoot = document.getElementById(portalGroup);

  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', portalGroup);
    document.body.appendChild(modalRoot);
  }

  const modalElement = useRef(document.createElement('div'));

  useEffect(() => {
    modalRoot.appendChild(modalElement.current);
    return () => modalRoot.removeChild(modalElement.current);
  }, [modalElement]);

  return createPortal(children, modalElement.current);
};

const Portal: FC<PortalProps> = (props) => {
  if (isSSR()) return null;
  return InternalPortal(props);
};

export default Portal;
