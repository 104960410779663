import * as moment from 'moment';

import { Differential_Type, Trade } from '@redsleeve/oilynx-domain';

const locale =
  typeof window !== 'undefined' &&
  ((window.navigator as any).userLanguage || window.navigator.language);
moment.locale(locale);

export function formatTimeUntilNow(date: number | string | Date) {
  return moment(date).fromNow();
}

export function formatCurrency(
  price: number,
  currency: string,
  showDecimals = true
) {
  return price.toLocaleString(undefined, {
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: showDecimals ? 2 : 0,
    style: 'currency',
  });
}

export function formatPercentage(value: number, showDecimals = true) {
  return value.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: showDecimals ? 2 : 0,
    style: 'percent',
  });
}

export function formatNumber(value: number, showDecimals = true) {
  return value.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: showDecimals ? 2 : 0,
  });
}

export function formatDate(value: number | Date) {
  return moment(value).format("DD MMM 'YY");
}

export function formatPriceBenchmark(
  trade: Pick<Trade, 'priceBenchmark' | 'currency'>
) {
  if (!trade.priceBenchmark) return undefined;
  return `${trade.priceBenchmark.priceIndex} ${formatPriceBenchmarkDifferential(
    trade,
    false
  )}`;
}

export function formatPriceBenchmarkDifferential(
  trade: Pick<Trade, 'priceBenchmark' | 'currency'>,
  showDecimals = true
) {
  if (!trade.priceBenchmark) return undefined;
  return `${Math.sign(trade.priceBenchmark.differential) > 0 ? '+' : '-'} ${
    trade.priceBenchmark.differentialType === Differential_Type.Fixed
      ? formatCurrency(
          Math.abs(trade.priceBenchmark.differential),
          trade.currency,
          showDecimals
        )
      : formatPercentage(Math.abs(trade.priceBenchmark.differential), false)
  }`;
}
