import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'outlined-light' | 'outlined' | 'primary' | 'danger' | 'none';
};

const Button: FC<ButtonProps> = ({ variant, className, children, ...rest }) => {
  let variantClassName =
    variant === 'outlined'
      ? `
    border-3/2 border-interaction-disabled
    font-bold text-white
    bg-background-avg hover:bg-background-dark 
        `
      : variant === 'outlined-light'
      ? `
    border-3/2 border-interaction-light
    text-white
    bg-background-avg hover:bg-background-dark 
        `
      : variant === 'primary'
      ? `
    border-0 
    font-bold text-white
    bg-interaction-enabled hover:bg-interaction-enabled
        `
      : variant === 'danger'
      ? `
    border-0 
    font-bold text-white
    bg-status-reject hover:bg-status-reject
        `
      : variant === 'none'
      ? ''
      : `
    border-0 
    font-bold text-interaction-light
    bg-background-avg hover:bg-background-avg 
        `;

  return (
    <button
      {...rest}
      className={clsx(
        className,
        `inline-flex justify-center 
        p-2 lg:px-4 lg:w-64
        shadow-sm rounded-md
        text-xs font-medium
        font-emp 
        focus:outline-none focus:ring-2 focus:ring-interaction-enabled
        `,
        variantClassName
      )}
    >
      {children}
    </button>
  );
};

export default Button;
